import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Add as AddIcon, Delete, List } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearResult,
  markLoading,
  search,
  setFilter,
  forceReload,
} from "../../../ducks/caseSearch";
import _ from "lodash";
import CaseSearchForm from "./CaseSearchForm";
import { caseDelete } from "../../../ducks/case";
import { LoadingButton } from "@mui/lab";
import TpoCaseOwnerSearchForm from "./TpoCaseOwnerSearchForm";
import TpoCaseSearchForm from "./TpoCaseSearchForm";

export default function CaseListPage() {
  const dispatch = useDispatch();
  const fetchIdRef = React.useRef(0);

  // tpo case search from modal
  const [openTpoCaseOwnerSearchForm, setOpenTpoCaseOwnerSearchForm] =
    useState(false);
  const handleCloseTpoCaseOwnerSearchForm = () => {
    setOpenTpoCaseOwnerSearchForm(false);
  };

  // tpo case search from modal
  const [openTpoCaseSearchForm, setOpenTpoCaseSearchForm] = useState(false);
  const handleCloseTpoCaseSearchForm = () => {
    setOpenTpoCaseSearchForm(false);
  };

  // case add menu state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const caseMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // delete record confirmation
  const [cfDel, SetCfDel] = useState(null);
  const [cfDelLoading, SetCfDelLoading] = useState(false);
  const handleCfDelClose = () => SetCfDel(null);
  const handleCfDelOk = () => {
    SetCfDelLoading(true);
    dispatch(caseDelete(cfDel.id))
      .then(() => {
        SetToastInfo({ type: "success", message: "ลบสำเร็จ" });
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        dispatch(forceReload());
        handleCfDelClose();
        SetCfDelLoading(false);
      });
  };

  useEffect(() => {
    // Set up
    // let isSubscribed = true;
    return () => {
      // isSubscribed = false;
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const rows = useSelector((state) => state.caseSearch.data);
  const total = useSelector((state) => state.caseSearch.meta.total);
  const loading = useSelector((state) => state.caseSearch.meta.loading);
  const filter = useSelector((state) => state.caseSearch.meta.filter);
  const reload = useSelector((state) => state.caseSearch.meta.forceReload);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    dispatch(markLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(
      search(rowsState.pageSize, rowsState.page, filter, fetchId, fetchIdRef)
    ).catch(({ response }) => {
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      dispatch(clearResult());
      dispatch(markLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, rowsState.page, rowsState.pageSize, reload]);

  const onSearchFormSubmit = (data) => {
    dispatch(setFilter(data));
  };

  const columns = useMemo(() => {
    return [
      {
        field: "action",
        headerName: "จัดการ",
        sortable: false,
        width: 100,
        renderCell: (params) => {
          return (
            <Stack spacing={1} direction="row">
              <IconButton
                type="button"
                size="small"
                variant="contained"
                color="primary"
                component={Link}
                to={`/cases/${params.id}/mgt`}
              >
                <List />
              </IconButton>
              {/* <IconButton
                type="button"
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  SetCfDel({
                    id: params.id,
                    msg: `ยืนยันการลบ การรับแจ้ง (${params.id})`,
                  });
                }}
              >
                <Delete />
              </IconButton> */}
            </Stack>
          );
        },
      },
      { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
      {
        field: "date",
        headerName: "วันที่",
        sortable: false,
        width: 100,
      },
      {
        field: "tpo_case_id",
        headerName: "TPO ID",
        sortable: false,
        minWidth: 100,
        maxWidth: 130,
      },
      {
        field: "victim_name",
        headerName: "ชื่อผู้เสียหาย",
        sortable: false,
        minWidth: 150,
        maxWidth: 180,
      },
      {
        field: "police_name",
        headerName: "ชื่อ พงส.",
        sortable: false,
        minWidth: 150,
        maxWidth: 180,
      },
      {
        field: "job_title",
        headerName: "ตำแหน่ง",
        sortable: false,
        minWidth: 150,
        maxWidth: 180,
      },
      {
        field: "policestation",
        headerName: "หน่วยงานเจ้าของเรื่อง",
        sortable: false,
        minWidth: 150,
        maxWidth: 180,
        valueGetter: (params) => {
          return params.row.policestation.ORG_ABBR;
        },
      },
    ];
  }, []);

  return (
    <>
      <TpoCaseOwnerSearchForm
        open={openTpoCaseOwnerSearchForm}
        onClose={handleCloseTpoCaseOwnerSearchForm}
      />
      <TpoCaseSearchForm
        open={openTpoCaseSearchForm}
        onClose={handleCloseTpoCaseSearchForm}
      />
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Dialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการลบข้อมูล</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {cfDel?.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!cfDelLoading && <Button onClick={handleCfDelClose}>ยกเลิก</Button>}
          <LoadingButton
            onClick={handleCfDelOk}
            loading={cfDelLoading}
            loadingPosition="start"
            startIcon={<Delete />}
            variant="contained"
            autoFocus
            color="error"
          >
            ลบ
          </LoadingButton>
          {/* <Button
            onClick={handleCfDelClose}
            variant="contained"
            color="error"
            autoFocus
            loading
          >
            ลบ
          </Button> */}
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Typography variant="h5">รายการ Case</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CaseSearchForm onSubmit={onSearchFormSubmit} loading={loading} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            // height: 700,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mb: 2 }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenTpoCaseSearchForm(true);
              }}
            >
              ยืนยันการรับแจ้งตาม พรก.ฯ(H)
            </Button>
            {/* <Button
              component={Link}
              to="/cases/add"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
            >
              เพิ่ม Case
            </Button> */}
            <Button
              aria-controls={caseMenuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={caseMenuOpen ? "true" : undefined}
              onClick={handleClick}
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
            >
              เพิ่ม Case
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={caseMenuOpen}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenTpoCaseOwnerSearchForm(true);
                }}
              >
                case จาก thai police online
              </MenuItem>
              <MenuItem component={Link} to="/cases/add">
                case ทั่วไป
              </MenuItem>
            </Menu>
          </Stack>
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[10, 20]}
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            onPageSizeChange={(pageSize) =>
              setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
            }
            getRowHeight={() => "auto"}
            // sx={{
            //   [`& .MuiDataGrid-cell`]: {
            //     py: 1,
            //   },
            // }}
            autoHeight
          />
        </Paper>
      </Grid>
    </>
  );
}
