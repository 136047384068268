import { Button, Grid, Stack } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React from "react";
import _ from "lodash";
import validationMsg from "../../../helpers/validationMsg";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import SubmitBtn from "../../SubmitBtn";
import { Search } from "@mui/icons-material";
import hrStatusSelect from "../../../data/selects/hr03Status";
import FieldInput from "../../form-fields/FieldInput";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { policeStationObjOptionsQuery } from "../../../data/queries/policestations";
import { banksOptionsQuery } from "../../../data/queries/banks";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";

const statusSelect = hrStatusSelect.filter((item) => item.value !== "new");

export default function Hr03MgtSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  const hr03Org = useSelector((state) => state.auth.user.hr03Org);
  const authRoles = useSelector((state) => state.auth.user.roles);

  const {
    isLoading: isOrgLoading,
    data: orgOptions,
    error: orgOptionsError,
  } = useQuery({
    ...policeStationObjOptionsQuery({
      bh_code: hr03Org?.ORG_CODE,
    }),
    // enable if not admin
    enabled: !authRoles[2] && !!hr03Org,
  });

  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(banksOptionsQuery());

  const renderPoliceStationField = () => {
    if (authRoles[2])
      return (
        <FieldAsyncAutoComplete
          name="policestation_org_code"
          label="หน่วยงาน"
          required
          controlProp={{ fullWidth: true }}
          basePath="/api/policestations"
          itemFormat={(row) => {
            return {
              value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
              label: row.ORG_ABBR,
            };
          }}
        />
      );

    if (hr03Org)
      return (
        <FieldAutoComplete
          name="policestation_org_code"
          label="หน่วยงาน"
          required
          controlProp={{ fullWidth: true }}
          inputProps={{
            sx: { width: "100%" },
            loading: isOrgLoading || orgOptionsError ? true : false,
            loadingText: orgOptionsError ? "Error" : "Loading…",
          }}
          options={orgOptions}
        />
      );

    return null;
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <FieldAutoComplete
            name="hr_type"
            label="ประเภท"
            options={[
              {
                value: 1,
                label: "HR03-1",
              },
              {
                value: 2,
                label: "HR03-2",
              },
            ]}
            controlProp={{ sx: { width: "100%" } }}
          />
        </Grid>
        {showStatusField && (
          <Grid item xs={12} md={6} lg={4} xl={2}>
            <FieldAutoComplete
              name="status"
              label="สถานะ"
              options={statusSelect}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4} xl={3}>
          {renderPoliceStationField()}
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAutoComplete
            name="bank_id"
            label="ธนาคาร"
            required
            controlProp={{ fullWidth: true }}
            inputProps={{
              sx: { width: "100%" },
              loading: isBankOptionsLoading || bankOptionsError ? true : false,
              loadingText: bankOptionsError ? "Error" : "Loading…",
            }}
            options={bankOptions}
          />
        </Grid>
        {/* <Grid item xs={12} xl={4}>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            spacing={2}
          >
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Stack>
        </Grid> */}
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // const now = new Date();
  // if (!errors.start_date && values.start_date) {
  //   const startDate = parseISO(values.start_date);
  //   if (isAfter(startDate, now)) {
  //     errors.start_date = "มากกว่าเวลาปัจจุบัน";
  //   }
  // }

  // if (!errors.end_date && values.end_date) {
  //   const endDate = parseISO(values.end_date);
  //   if (isAfter(endDate, now)) {
  //     errors.end_date = "มากกว่าเวลาปัจจุบัน";
  //   }
  // }

  // if (
  //   !errors.start_date &&
  //   !errors.end_date &&
  //   values.start_date &&
  //   values.end_date &&
  //   isAfter(parseISO(values.start_date), parseISO(values.end_date))
  // ) {
  //   errors.start_date = "ไม่ถูกต้อง";
  // }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  hr_type: Joi.number().valid(1, 2).allow(null),
  policestation_org_code: Joi.object({
    org_code: Joi.string()
      .pattern(/^\d{5}$/)
      .required(),
    org_type: Joi.string().uppercase().length(2).required(),
  }),
  bank_id: Joi.number().allow(null),
  // start_date: Joi.date()
  //   .min(defaultJoiDates.minDate)
  //   .max(defaultJoiDates.forceUTC(startOfToday()))
  //   .allow(null),
  // end_date: Joi.date()
  //   .min(defaultJoiDates.minDate)
  //   .max(defaultJoiDates.forceUTC(startOfToday()))
  //   .allow(null),
  status: Joi.string().allow(null),
  search: Joi.string().max(50).allow(null),
};

const schema = Joi.object(validateRules).error(validationMsg());
